.main_form {
    &.results {
        .main_form__fr {
            max-width: vw(1175);
            @media all and (max-width: 860px) {
                max-width: 100%;
            }
        }
        .main_form__title {
            max-width: 100%;
            @media all and (max-width: 860px) {
                text-align: left !important;
            }
        }
        .main_form__ques--title {
            margin-bottom: vw(20);
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: 700;
            font-size: vw(18);
            line-height: 140%;
            color: #FFFFFF;
            margin-top: vw(40);
            @media all and (max-width: 860px) {
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 20px;
            }
        }
    }
    &__result {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 860px) {
            flex-direction: column;
        }
        &--l {
            width: 100%;
            margin-bottom: auto;
            max-width: 100%;
        }
        &--title {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(40);
            color: #FFFFFF;
            margin-bottom: vw(23);
            @media all and (max-width: 860px) {
                font-size: 14px;
                line-height: normal;
                text-align: left;
                margin-bottom: 20px;
                max-width: 160px;
                width: 100%;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(16);
            line-height: 140%;
            color: #FFFFFF;
            @media all and (max-width: 860px) {
                margin-bottom: 30px;
                font-size: 12px;
                line-height: 140%;
            }
        }
        &--list {    
            width: calc(100% + 40px);
            margin-bottom: vw(14);
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            @media all and (max-width: 860px) {
                width: 100%;
                margin-bottom: 25px;
            }
            ul {
                list-style: none;
                padding: 0;
                width: calc(50% - 20px);
                margin: 0;
                @media all and (max-width: 860px) {
                    width: 100%;
                }
                li {
                    margin-bottom: vw(20);
                    position: relative;
                    padding-left: vw(40);
                    &:before {
                        content: '';
                        position: absolute;
                        width: vw(30);
                        height: vw(30);
                        border-radius: 50%;
                        left: 0;
                        top: 50%;
                        margin-top: vw(-15);
                        background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='14' stroke='white' stroke-width='2'/%3E%3Cpath d='M9 13L13.5 19L21 10' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") 50% no-repeat;
                        background-size: 100%;
                        @media all and (max-width: 860px) {
                            width: 20px;
                            height: 20px;
                            background-size: 100%;
                            margin-top: -10px;
                        }
                    }
                    &.full_info {
                        &:before {
                            @media all and (max-width: 860px) {
                                margin-top: -35px;
                            }
                        }
                    }
                    @media all and (max-width: 860px) {
                        margin-bottom: 20px;
                        padding-left: 30px;
                    }
                }
            }
            @media all and (max-width: 860px) {
                margin-bottom: 20px;
            }
        }
        &--tit {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(16);
            line-height: 140%;
            color: #FFFFFF;
            margin-bottom: vw(5);
            @media all and (max-width: 860px) {
                font-size: 12px;
                line-height: 140%;
            }
        }
        &--txt {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(16);
            line-height: 140%;
            color: #FFFFFF;
            @media all and (max-width: 860px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
        &--btn {
            margin-top: vw(42);
            display: block;
            margin-left: vw(15);
            text-decoration: none;
            margin-top: vw(24);
            margin-right: auto;
            background: #FF5000;
            border: 1px solid #FF5000;
            border-radius: 10px;
            max-width: vw(340);
            width: 100%;
            padding: vw(18.5) vw(10);
            text-align: center;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            transition: all .3s ease-out;
            font-size: vw(20);
            text-transform: uppercase;
            color: #FFFFFF;
            cursor: pointer;
            position: relative;
            z-index: 1;
            &:hover {
                background: #fff;
                color: #FF5000;
            }
            @media all and (max-width: 860px) {
                margin-top: 30px;
                max-width: 100%;
                padding: 12px 10px;
                font-size: 13px;
            }
        }
        &--r {
            font-size: 0;
            max-width: vw(476);
            width: 100%;
            margin-left: vw(10);
            @media all and (max-width: 860px) {
                max-width: 170px;
            }
        }
        &--ovf {
            max-height: vw(560);
            height: auto;
            overflow: auto;
            overflow-x: hidden;
            > div:last-child {
                margin-bottom: 0;
            }
            @media all and (max-width: 860px) {
                max-height: 355px;
            }
        }
    }
}