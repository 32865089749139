.footer {
    background: #66C9BA url('../img/fot_bg.png') 0 0 / cover no-repeat;
    border-radius: vw(122) vw(112) 0 0;
    padding-top: vw(43);
    padding-bottom: vw(18);
    @media all and (max-width: 1023зч) {
        padding-top: 16px;
        border-radius: 70px 70px 0 0;
        background: #66C9BA;
    }
    &__t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: vw(22);
        @media all and (max-width: 1023px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
        }
        &--logo {
            flex: 0 0 vw(200);
            font-size: 0;
            margin-right: vw(140);
            @media all and (max-width: 1023px) {
                margin: 0;
                flex: none;
                margin-bottom: 30px;
                max-width: 118px;
                width: 100%;
                margin-right: 40px;
            }
        }
        &--menu {
            width: 100%;
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
                padding: 0;
                list-style: none;
                @media all and (max-width: 1023px) {
                    flex-direction: column;
                }
                li {
                    a {
                        font-family: 'gm', serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: vw(24);
                        color: #FFFFFF;
                        margin-left: vw(20);
                        text-decoration: none;
                        display: block;
                        margin-bottom: vw(5);
                        margin-top: vw(5);
                        &:hover {
                            text-decoration: underline;
                        }
                        @media all and (max-width: 1023px) {
                            margin: 0;
                            font-size: 18px;
                            margin-bottom: 18px;
                        }
                    }
                }
            }
        }
    }
    &__b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 1023px) {
            flex-direction: column-reverse;
            text-align: center;
        }
        &--copy {
            max-width: 206px;
            width: 100%;
            margin-right: vw(10);
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            @media all and (max-width: 1023px) {
                margin-bottom: 6px;
            }
        }
        &--politic {
            margin-right: auto;
            margin-left: 9%;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-decoration: none;
            border-bottom: 1px solid #fff;
            color: #FFFFFF;
            transition: all .3s ease-out;
            &:hover {
                border-color: transparent;
            }
            @media all and (max-width: 1023px) {
                margin-left: auto;
                margin-bottom: 37px;
            }
        }
        &--info {
            display: flex;
            align-items: center;
            @media all and (max-width: 1023px) {
                flex-direction: column-reverse;
            }
        }
        &--soc {
            display: flex;
            align-items: center;
            a {
                display: block;
                margin-left: 32px;
                font-size: 0;
                @media all and (max-width: 1023px) {
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-bottom: 30px;
                }
                transition: all .3s ease-out;
                &:hover {
                    opacity: .7;
                }
            }
        }
        &--tel {
            margin-left: 74px;
            text-decoration: none;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;
            @media all and (max-width: 1023px) {
                margin-left: 20px;
                margin: 0;
                margin-bottom: 32px;
            }
            border-bottom: 5px solid transparent;
            padding-bottom: 2px;
            padding-top: 2px;
            transition: all .3s ease-out;
            &:hover { 
                border-color: #FFf;
            }
        }
    }
}