@font-face {
    font-family: 'gm';
    src: url('../fonts/Geometria/Geometria.woff') format('woff');
}
@font-face {
    font-family: 'gml';
    src: url('../fonts/Geometria/Geometria-Light.woff') format('woff');
}
@font-face {
    font-family: 'gmm';
    src: url('../fonts/Geometria/Geometria-Medium.woff') format('woff');
}