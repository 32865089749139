h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

.container {
    @include container();
    @include sm(max-width, rem(510));
    @include md(max-width, rem(690));
    @include lg(max-width, rem(930));
    @include xl(max-width, rem(1110));
}
.center_block {
    max-width: 1333px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px; 
    padding-right: 15px;
}
body{
    box-sizing: border-box;
}
*,::after,::before{
    box-sizing: inherit;
}
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex: 0 0 auto;
}
.clear{
    clear: both;
}

img {
    max-width: 100%;
    height: auto;
}
// scrollbar
.main_rev__block--text::-webkit-scrollbar {
    background: #F9F9F9;
    border: 1px solid #66C9BA;
    border-radius: 5px;
    width: 11px;
    height: 11px;
}

/* ползунок скроллбара */
.main_rev__block--text::-webkit-scrollbar-thumb {
    background: #66C9BA;
    border-radius: 5px;
}

/* Стрелки */

// ::-webkit-scrollbar-button:vertical:start:decrement {
//     background: linear-gradient(120deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//     linear-gradient(240deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//     linear-gradient(0deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
//     background-color: #f6f8f4;
// }

// ::-webkit-scrollbar-button:vertical:end:increment {
//     background:
//         linear-gradient(300deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(60deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(180deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
//     background-color: #f6f8f4;
// }

// ::-webkit-scrollbar-button:horizontal:start:decrement {
//     background:
//         linear-gradient(30deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(150deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(270deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
//     background-color: #f6f8f4;
// }

// ::-webkit-scrollbar-button:horizontal:end:increment {
//     background:
//         linear-gradient(210deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(330deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
//         linear-gradient(90deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
//     background-color: #f6f8f4;
// }
// scrollbar end