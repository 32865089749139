.main_ser {
    margin-top: 71px;
    margin-bottom: 85px;
    overflow: hidden;
    @media all and (max-width: 640px) {
        margin-top: 40px;
        margin-bottom: 44px;
    }
    .slick-list {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    &__title {
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        color: #26364B;
        margin-bottom: 40px;
        @media all and (max-width: 640px) {
            margin-bottom: 28px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 24px;
        }
    }
    &__block {
        max-width: 308px;
        width: 100%;
        margin-right: 22px;
        position: relative;
        transition: all .3s ease-out;
        top: 0;
        @media all and (min-width: 910px) {
            &:hover {
                top: -10px;
            }
        }
        @media all and (max-width: 640px) {
            max-width: 290px;
            margin-left: 0px;
            margin-right: 14px;
        }
    }
    .slick-prev {
        &:before {
            display: none;
        }
        border: 1px solid #66C9BA;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.596356 9.47867L9.36981 0.705225C9.66025 0.424715 10.1231 0.432768 10.4036 0.723217C10.6773 1.00656 10.6773 1.45572 10.4036 1.73902L2.14705 9.99557L10.4036 18.2521C10.689 18.5376 10.689 19.0004 10.4036 19.2859C10.1181 19.5714 9.65528 19.5714 9.36981 19.2859L0.596356 10.5125C0.31092 10.2269 0.31092 9.76415 0.596356 9.47867Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
        top: -64px;
        left: unset;
        right: 156px;
        transition: all .3s ease-out;
        &:hover {
            background: #66C9BA url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.596356 9.47867L9.36981 0.705225C9.66025 0.424715 10.1231 0.432768 10.4036 0.723217C10.6773 1.00656 10.6773 1.45572 10.4036 1.73902L2.14705 9.99557L10.4036 18.2521C10.689 18.5376 10.689 19.0004 10.4036 19.2859C10.1181 19.5714 9.65528 19.5714 9.36981 19.2859L0.596356 10.5125C0.31092 10.2269 0.31092 9.76415 0.596356 9.47867Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") 50% no-repeat;
        }
    }
    .slick-next {
        &:before {
            display: none;
        }
        border: 1px solid #66C9BA;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4036 8.97867L1.63019 0.205225C1.33975 -0.0752854 0.876912 -0.0672317 0.596402 0.223217C0.322746 0.506555 0.322746 0.955723 0.596402 1.23902L8.85295 9.49557L0.596402 17.7521C0.310965 18.0376 0.310965 18.5004 0.596402 18.7859C0.881925 19.0714 1.34472 19.0714 1.63019 18.7859L10.4036 10.0125C10.6891 9.72695 10.6891 9.26415 10.4036 8.97867Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
        top: -64px;
        right: 6px;
        transition: all .3s ease-out;
        &:hover {
            background: #66C9BA url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4036 8.97867L1.63019 0.205225C1.33975 -0.0752854 0.876912 -0.0672317 0.596402 0.223217C0.322746 0.506555 0.322746 0.955723 0.596402 1.23902L8.85295 9.49557L0.596402 17.7521C0.310965 18.0376 0.310965 18.5004 0.596402 18.7859C0.881925 19.0714 1.34472 19.0714 1.63019 18.7859L10.4036 10.0125C10.6891 9.72695 10.6891 9.26415 10.4036 8.97867Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") 50% no-repeat;
        }
    }
    @media all and (max-width: 810px) {
        position: relative;
        padding-bottom: 87px;
        .slick-slider {
            position: unset;
        }
        .slick-prev {
            font-size: 0; 
            width: 72px;
            height: 72px;
            border-radius: 50%;
            z-index: 11;
            margin-top: -36px;
            border: 1px solid #66C9BA;
            background: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
            transition: all .3s ease-out;
            transform: rotate(180deg);
            left: 20px;
            bottom: 24px;
            margin-top: 0;
            top: unset;
            &:hover {
                background: #66C9BA url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") 50% no-repeat;
            }
            &:before {
                display: none;
            }
        }
        .slick-next {
            font-size: 0; 
            width: 72px;
            height: 72px;
            border-radius: 50%;
            z-index: 11;
            margin-top: -36px;
            border: 1px solid #66C9BA;
            background: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
            transition: all .3s ease-out;
            right: 20px;
            bottom: 24px;
            margin-top: 0;
            transform: unset;
            top: unset;
            &:hover {
                background: #66C9BA url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") 50% no-repeat;
            }
            &:before {
                display: none;
            }
        }
        .slick-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 45px;
            left: 0;
            right: 0;
            pointer-events: none;
            li {
                pointer-events: all;
                margin-left: 5px;
                margin-right: 5px;
                width: 5px;
                button {
                    margin-left: 5px;
                    margin-right: 5px;
                    background: #DCF3EF;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    padding: 0;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background: #66C9BA;
                    }
                }
            }
        }
    }
}
.lg-backdrop {
    background: rgba(102, 201, 186, 0.8);
}
.lg-toolbar .lg-close {
    width: 48px;
    height: 48px;
    padding: 0;
    display: block !important;
    background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.2451 24.0005L47.1205 5.12431C48.2932 3.95255 48.2932 2.05132 47.1205 0.879568C45.9478 -0.293189 44.0486 -0.293189 42.8759 0.879568L24.0005 19.7558L5.1241 0.879568C3.95139 -0.293189 2.05224 -0.293189 0.879531 0.879568C-0.293177 2.05132 -0.293177 3.95255 0.879531 5.12431L19.7559 24.0005L0.879531 42.8767C-0.293177 44.0484 -0.293177 45.9497 0.879531 47.1214C1.46589 47.7068 2.23435 48 3.00181 48C3.76928 48 4.53774 47.7068 5.1241 47.1204L24.0005 28.2442L42.8759 47.1204C43.4623 47.7068 44.2307 48 44.9982 48C45.7656 48 46.5341 47.7068 47.1205 47.1204C48.2932 45.9487 48.2932 44.0474 47.1205 42.8757L28.2451 24.0005Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
    margin-top: 22px;
    margin-right: 32px;
    &:after {
        display: none !important;
    }
}
.lg-toolbar .lg-download {
    display: none;
}
.lg-outer .lg-content {
    max-width: 1023px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.lg-container .lg-prev {
    &:after {
        display: none !important; 
    }
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.866972 17.9596L17.4903 1.33624C18.0407 0.804742 18.9176 0.820001 19.4491 1.37033C19.9676 1.90718 19.9676 2.75823 19.4491 3.295L3.80512 18.939L19.4491 34.583C19.9899 35.1239 19.9899 36.0009 19.4491 36.5418C18.9081 37.0826 18.0313 37.0826 17.4903 36.5418L0.866972 19.9184C0.326143 19.3774 0.326143 18.5005 0.866972 17.9596Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
    left: -117px;
    transition: all .3s ease-out;
    &:hover {
        background: #fff url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.866972 17.9596L17.4903 1.33624C18.0407 0.804742 18.9176 0.820001 19.4491 1.37033C19.9676 1.90718 19.9676 2.75823 19.4491 3.295L3.80512 18.939L19.4491 34.583C19.9899 35.1239 19.9899 36.0009 19.4491 36.5418C18.9081 37.0826 18.0313 37.0826 17.4903 36.5418L0.866972 19.9184C0.326143 19.3774 0.326143 18.5005 0.866972 17.9596Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
    }
    @media all and (max-width: 1280px) {
        display: none !important;
    }
}
.lg-container .lg-next {
    &:before {
        display: none !important; 
    }
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9596L2.50989 1.33624C1.95957 0.804742 1.08262 0.820001 0.551129 1.37033C0.0326223 1.90718 0.0326223 2.75823 0.551129 3.295L16.1951 18.939L0.551129 34.583C0.0103008 35.1239 0.0103008 36.0009 0.551129 36.5418C1.09212 37.0826 1.96899 37.0826 2.50989 36.5418L19.1333 19.9184C19.6741 19.3774 19.6741 18.5005 19.1333 17.9596Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
    right: -117px;
    transition: all .3s ease-out;
    &:hover {
        background: #fff url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9596L2.50989 1.33624C1.95957 0.804742 1.08262 0.820001 0.551129 1.37033C0.0326223 1.90718 0.0326223 2.75823 0.551129 3.295L16.1951 18.939L0.551129 34.583C0.0103008 35.1239 0.0103008 36.0009 0.551129 36.5418C1.09212 37.0826 1.96899 37.0826 2.50989 36.5418L19.1333 19.9184C19.6741 19.3774 19.6741 18.5005 19.1333 17.9596Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
    }
    @media all and (max-width: 1280px) {
        display: none !important;
    }
}
.lg-counter {
    display: none !important;
}