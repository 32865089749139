// burg
.site-panel-wrap {
    display: none;
}
@media all and (max-width: 1023px) {
    .site-panel-wrap {
        display: block;
        z-index: 100;
        margin-left: auto;
        position: relative;
        
        & .burger-wrap {
            width: 25px;
            height: 25px;
            margin-left: auto;
        
        & .burger-btn {
            width: 25px;
            height: 25px;
            display: block;
            cursor: pointer;
            
            span {
            margin-bottom: 6px;
            display: block;
            height: 2px;
            background: #FF5000;
            width: 25px;
            border-radius: 3px;
            }
        }
        
        & .burger-close-btn {
            width: 19px;
            height: 19px;
            background: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1803 9.5002L18.6519 2.02837C19.116 1.56455 19.116 0.811983 18.6519 0.348162C18.1877 -0.116054 17.4359 -0.116054 16.9717 0.348162L9.5002 7.81999L2.02829 0.348162C1.56409 -0.116054 0.812345 -0.116054 0.348148 0.348162C-0.116049 0.811983 -0.116049 1.56455 0.348148 2.02837L7.82006 9.5002L0.348148 16.972C-0.116049 17.4358 -0.116049 18.1884 0.348148 18.6522C0.580246 18.8839 0.88443 19 1.18822 19C1.49201 19 1.79619 18.8839 2.02829 18.6518L9.5002 11.18L16.9717 18.6518C17.2038 18.8839 17.508 19 17.8118 19C18.1156 19 18.4198 18.8839 18.6519 18.6518C19.116 18.188 19.116 17.4354 18.6519 16.9716L11.1803 9.5002Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
            cursor: pointer;
            margin-bottom: 9px;
        }
        
        &:before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
        }
        
        &.opened {
            .burger-body {
                right: 0%;
            }
            
            &:before {
                visibility: visible;
                opacity: 1;
                background: rgba(0,0,0,0.5);
            }
        }
        
        & .burger-body {
            position: fixed;
            top: 0;
            bottom: 0;
            right: -100%;
            -webkit-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 320px;
            background: #66C9BA;
            height: 100vh;
            overflow-y: auto;
            padding-top: 17px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: column;
            ul {
                display: block !important;
                margin: 0;
                list-style: none;
                padding: 0;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                li {
                    a {
                        display: block;
                        margin-bottom: 15px;
                        font-family: 'gmm', serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        text-align: center;
                        color: #FFFFFF; 
                        padding-bottom: 3px;
                        border-width: 2px;
                        display: inline-block;
                        margin-top: 0;
                    }
                }
            }
        }
        }
    }
    .burg {
        &__top {
            padding-left: 16px;
            padding-right: 18px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding-bottom: 18px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        &__logo {
            max-width: 88px;
            width: 100%;
        }
        &__tel {
            text-align: center;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            text-decoration: none;
            display: block;
            color: #FFFFFF;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            margin-bottom: 20px;
        }
        &__soc {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            a {
                margin-left: 10px;
                margin-right: 10px;
                width: vw(30);
                height: vw(30);
                margin-bottom: 10px;
            }
        }
    }
}
// burg end
.header {
    background: url('../img/head_bg.png') 0 0 / 100% no-repeat;
    border-radius: 0 0 112px 122px;
    @media all and (max-width: 830px) {
        border-radius: 0 0 70px 70px;
        background: #FFF7F3 url('../img/head_bg.png') 0 70% / 100% no-repeat;
    }
    &__in {
        margin-bottom: vw(-105);
        @media all and (max-width: 1023px) {
            background: radial-gradient(50% 50% at 20% 63%, #ffb08c66 0%, rgba(255, 196, 169, 0) 100%);
        }
    }
    &__t {
        display: flex;
        justify-content: space-between;
        padding-bottom: vw(35);
        padding-top: vw(18);
        padding-left: vw(28);
        @media all and (max-width: 1023px) {
            padding-left: 0;
        }
        @media all and (max-width: 830px) {
            align-items: flex-end;
            padding-top: 11px;
        }
        &--l {
            flex: 0 0 vw(197);
            margin-right: vw(108);
            @media all and (max-width: 1160px) {
                margin-right: 20px;
            }
            @media all and (max-width: 1023px) {
                flex: 0 0 137px;
            }
            @media all and (max-width: 830px) {
                flex: none;
                max-width: 145px;
                width: 100%;
            }
            @media all and (max-width: 480px) {
                max-width: 86px;
                width: 100%;
            }
        }
        &--logo {
            display: block;
            font-size: 0;
            position: relative;
            bottom: vw(-8);
            @media all and (max-width: 960px) {
                bottom: 0;
            }
        }
        &--r {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-top: vw(18);
            @media all and (max-width: 1023px) {
                padding-top: 0;
            }
        }
        &--tt {
            margin-bottom: vw(40);
            @media all and (max-width: 830px) {
                margin-bottom: 0px;
            }
        }
        &--info {
            display: flex;
            align-items: center;
            margin-left: auto;
            @media all and (max-width: 830px) {
                flex-direction: column-reverse;
            }
        }
        &--soc {
            display: flex;
            align-items: center;
            a {
                display: block;
                margin-left: vw(32);
                font-size: 0;
                @media all and (max-width: 1023px) {
                    margin-left: 20px;
                }
                @media all and (max-width: 830px) {
                    margin-left: 25px;
                    margin-bottom: 27px;
                    width: 24px;
                    height: 24px;
                }
                transition: all .3s ease-out;
                svg {
                    height: 100%;
                    width: 100%;
                    path {
                        transition: all .3s ease-out;
                    }
                }
                &:hover svg {
                    path {
                        fill: #FF5000;
                    }
                }
                &:first-child:hover svg {
                    path {
                        fill: #FF5000;
                        stroke: #FF5000;
                    }
                }
            }
        }
        &--tel {
            margin-left: vw(52);
            text-decoration: none;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(26);
            color: #FF5000;
            border-bottom: 5px solid transparent;
            transition: all .3s ease-out;
            padding-top: vw(2);
            padding-bottom: vw(2);
            &:hover {
                border-color: #FF5000;
            }
            @media all and (max-width: 1023px) {
                margin-left: 20px;
                font-size: 20px;
            }
            @media all and (max-width: 830px) {
                display: none;
            }
        }
        &--tb {
            width: 100%;
            max-width: vw(965);
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media all and (max-width: 1023px) {
                    display: none;
                }
                li {
                    a {
                        margin-top: vw(10);
                        padding-bottom: vw(5);
                        border-bottom: 5px solid transparent;
                        font-family: 'gm', serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: vw(24);
                        text-decoration: none;
                        transition: all .3s ease-out;
                        color: #26364B;
                        &:hover {
                            border-color: #FF5000;  
                            text-shadow: 0px 0px 0px black;
                        }
                    }
                }
            }
        }
    }
    &__b {
        padding-left: vw(14);
        display: flex;
        align-items: center;
        @media all and (max-width: 1023px) {
            flex-direction: column-reverse;
            padding-left: 0;
            position: relative;
        }
        &--l {
            flex: 0 0 vw(521);
            font-size: 0;
            background: radial-gradient(51% 50% at 50% 80%, #66C9BA 0%, rgba(226, 255, 251, 0) 100%);
            margin-right: vw(25);
            img {
                position: relative;
                @media all and (min-width: 1024px) {
                    bottom: -8px;
                }
                bottom: -1px;
            }
            @media all and (max-width: 1023px) {
                background: none;
                margin-right: 0;
                position: absolute;
                flex: none;
                width: 180px;
                z-index: 0;
                pointer-events: none;
                bottom: 123px;
                left: calc(50% - 246px);
            }
            @media all and (max-width: 620px) {
                width: 145px;
                left: -11px;
            }
        }
        &--r {
            width: 100%;
            position: relative;
            z-index: 1;
            padding-top: vw(43);
        }
        &--title {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(40);
            line-height: 120%;            
            color: #26364B;  
            margin-bottom: vw(29);  
            span {
                color: #66C9BA;
                font-size: vw(37);
            }   
            @media all and (max-width: 1023px) {
                font-size: 20px;
                line-height: normal;
                margin-bottom: 125px;
                span {
                    font-size: 20px;
                }
            }
            @media all and (max-width: 700px) {
                margin-bottom: 70px;
            }
            @media all and (max-width: 620px) {
                font-size: 24px;
            }
            @media all and (max-width: 370px) {
                margin-bottom: 50px;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: 400;
            font-size: vw(20);
            color: #3D3D3D;
            line-height: 140%;
            max-width: vw(680);
            width: 100%;
            margin-bottom: vw(38);
            @media all and (max-width: 1023px) {
                font-size: 18px;
                line-height: normal;
                margin-bottom: 25px;
                width: 50%;
                margin-left: auto;
            }
            @media all and (max-width: 620px) {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 22px;
                max-width: 200px;
            }
        }
        &--btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media all and (max-width: 1023px) {
                flex-direction: column;
            }
        }
        &--btn {
            text-decoration: none;
            background: #FF5000;
            border: 2px solid #FF5000;
            border-radius: 10px;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(24);
            text-transform: uppercase;
            color: #FFFFFF;
            padding: vw(35) vw(5);
            max-width: calc(60% - vw(30));
            width: 100%;
            display: block;
            text-align: center;
            transition: all .3s ease-out;
            margin-right: vw(30);
            &:hover {
                background: #fff;
                color: #FF5000;
            }
            &.blue {
                color: #66C9BA;
                background: #F4FFFD;
                border-color: #66C9BA;
                margin-right: 0;
                max-width: 40%;
                &:hover {
                    background: #66C9BA;
                    color: #fff;
                }
            }
            @media all and (max-width: 1023px) {
                max-width: 100%;
                margin-bottom: 20px;
                margin-right: 0;
                font-size: 13px;
                padding: 17.5px 10px;
                &.blue {
                    margin-bottom: 0;
                    max-width: 100%;
                }
            }
        }
    }
}
.block_info {
    max-width: vw(1668);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: #66C9BA;
    border-radius: 30px;
    padding-top: vw(45);
    padding-bottom: vw(2);
    position: relative;
    bottom: vw(-105);
    margin-bottom: vw(245);
    @media all and (max-width: 1720px) {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
        max-width: 100%;
    }
    @media all and (max-width: 1200px) {
        padding-bottom: 20px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-top: 40px;
    }
    @media all and (max-width: 1023px) {
        margin-top: 40px;
    }
    &__in {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1375px;
    }
    &__block {
        text-align: center;
        padding: 0 vw(30);
        width: 25%;
        margin-bottom: vw(30);
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:not(:last-child) {
            border-right: 3px solid rgba(255,255,255,.3);
            @media all and (max-width: 1200px) {
                border-right: 0;
            }
        }
        @media all and (max-width: 1200px) {
            width: calc(50% - 10px);
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 20px;
        }
        &--img {
            max-width: vw(90);
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: vw(30);
            @media all and (max-width: 1023px) {
                margin-bottom: 10px;
                max-width: 48px;
            }
        }
        &--title {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(24);
            color: #FFFFFF;
            @media all and (max-width: 1023px) {
                font-size: 14px;
            }
        }
    }
}