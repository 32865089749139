.main_bl {
    padding-top: vw(130);
    padding-bottom: vw(150);
    background: url('../img/bg_b.png') 100% calc(100% - 50px) no-repeat;
    
    @media all and (max-width: 1023px) {
        background: none;
        padding-bottom: 50px;
    }
    @media all and (max-width: 980px) {
        border-radius: 70px 0 70px 0;
    }
    @media all and (max-width: 640px) {
        padding-top: 50px;
        margin-bottom: -268px;
        padding-bottom: 226px;
    }
    &__block {
        display: flex;
        align-items: center;
        margin-bottom: vw(115);
        @media all and (max-width: 980px) {
            flex-direction: column;
            margin-bottom: 78px;
        }
        &--l {
            width: 50%;
            @media all and (max-width: 1023px) {
                width: 100%;
                margin-bottom: 23px;
            }
        }
        &--title {
            margin-bottom: vw(30);
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(40);
            line-height: 120%;
            color: #26364B;
            @media all and (max-width: 1023px) {
                br {
                    display: none;
                }
            }
            @media all and (max-width: 1023px) {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 27px;
            }
        }
        &--list {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                margin-bottom: vw(20);
                @media all and (max-width: 1023px) {
                    align-items: flex-start;
                    margin-bottom: 20px;
                    br {
                        display: none;
                    }
                }
            }
        }
        &--img {
            flex: 0 0 vw(40);
            margin-right: vw(20);
            @media all and (max-width: 860px) {
                flex: 0 0 40px;
                margin-right: 20px;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: 400;
            font-size: vw(20);
            line-height: 140%;
            color: #3D3D3D;
            @media all and (max-width: 1023px) {
                font-size: 14px;
            }
        }
        &--r {
            width: 50%;
            text-align: right;
            img {
                margin-left: auto;
                max-width: 624px;
                width: 100%;
            }
            iframe {
                width: 100% !important;
            }
            @media all and (max-width: 980px) {
                width: 100%;
                text-align: center;
                iframe {
                    height: 344px !important;
                }
            }
            @media all and (max-width: 640px) {
                iframe {
                    height: 224px !important;
                }
            }
        }
        &.block_phone {
            margin-top: vw(188);
            @media all and (max-width: 980px) {
                flex-direction: column-reverse;
            }
            @media all and (max-width: 960px) {
                margin-top: 80px;
            }
        }
    }
    &__phone {
        max-width: 370px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-top: 90px;
        padding-bottom: 15px;
        @media all and (max-width: 480px) {
            max-width: 290px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .phone-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 335px;
            width: 100%;
            pointer-events: none;
            margin-left: auto;
            margin-right: auto;
            @media all and (max-width: 480px) {
                margin-left: -5px;
            }
        }
        @media all and (max-width: 480px) {
            margin-bottom: 40px;
        }
    }
    &__chat {
        padding: 0px 65px;
        padding-left: 66px;
        height: 406px;
        margin-bottom: 15px;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        @media all and (max-width: 960px) {
            padding-right: 45px;
            padding-left: 33px;
        }
        @media all and (max-width: 640px) {
            margin-top: 20px !important;
        }
        @media all and (max-width: 480px) {
            height: 320px;
            padding-right: 35px;
            padding-left: 45px;
        }
        &--item {
            overflow-y: scroll;
            transform: rotate(180deg);
            direction: rtl;
            overflow: auto;
            &::-webkit-scrollbar
            {
                width: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
    
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) ;
                border-radius: 10px;
                background-color: #fff ;
                border-left: 10px solid white;
                border-right: 10px solid white;
            }
    
            &::-webkit-scrollbar-thumb
            {
                border-radius: 20px;
                -webkit-box-shadow: inset 0 0 px #FF5000 ;
                background-color: #FF5000   ;
    
            }
        }
        .msg {
            transform: rotate(180deg);
            direction: ltr;
        }
        &--text {
            padding: 11px 16px;
            padding-left: 24px;
            background: #F4F4F4;
            border-radius: 20px 20px 20px 0px;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 7px;
            text-align: left;
            @media all and (max-width: 480px) {
                max-width: 202px;
                width: 100%;
            }
        }
        &--bl {
            width: 100%;
            max-width: 234px;
            margin-left: auto;
            margin-right: auto;
        }
        &--img {
            margin-bottom: 7px;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            overflow: hidden;
        }
        &--call {
            
        }   
        &--user {
            .main_bl__chat--text {
                background: #C2FFF6;
                border-radius: 20px 20px 20px 0px;
            }
            .main_bl__chat--img {
                margin-left: auto;
            }
        }
    }
}

.main_bl__chat--bl {
    transition: all 1s ease-out;
}
.abc .atransomas {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.bl1 {
    animation-delay: 1s;
}
.bl2 {
    animation-delay: 3s;
}
.bl3 {
    animation-delay: 5s;
}
.bl4 {
    animation-delay: 7s;
}
.bl5 {
    animation-delay: 9s;
}
.bl6 {
    animation-delay: 11s;
}
.bl7 {
    animation-delay: 13s;
}
.bl8 {
    animation-delay: 15s;
}
.bl9 {
    animation-delay: 17s;
}
@-webkit-keyframes dvizheniyaUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        height: 0;
    }
  
    to {
        opacity: 1;
        height: max-content !important;
        -webkit-transform: none;
        transform: none;
    }
}
  @keyframes dvizheniyaUp {
    from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    height: 0;
    }
  
    to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    height: max-content !important;
    }
  }
  
 .abc .dvizheniyaUp {
    -webkit-animation-name: dvizheniyaUp;
    animation-name: dvizheniyaUp;
  }
  .scrollbar-track-y {
      right: unset !important;
      left: 13px !important;
      height: calc(100% - 120px) !important;
      margin-top: 60px !important;
      margin-bottom: 60px !important;
      @media all and (max-width: 480px) {
          left: 5px !important;
          height: calc(100% - 60px) !important;
          margin-top: 30px !important;
          margin-bottom: 30px !important; 
      }
  }
  .scrollbar-track {
      background: #fff !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
        border-radius: 5px !important;
  }
  .scrollbar-thumb {
      width: 20px !important;
      height: 20px !important;
      background: #FF5000 !important;
        border-radius: 45px !important;
        left: -5px !important;
  }