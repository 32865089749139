.main_form {
    position: relative;
    z-index: 10;
    min-height: vw(976);
    overflow: hidden;
    @media all and (max-width: 860px) {
        min-height: 580px;
        margin-top: 80px;
    }
    &:before {
        position: absolute;
        content: '';
        background: url('../img/mac.png') 50% / contain no-repeat;
        width: vw(1618);
        height: vw(976);
        left: 50%;
        margin-left: vw(-809);
        top: 0;
        @media all and (max-width: 860px) {
            background: url('../img/phone.svg') 50% / contain no-repeat;
            width: 289px;
            left: 50%;
            height: 572px;
            margin-left: -144.5px;
        }
    }
    &__item {
        max-width: vw(1668);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @media all and (max-width: 860px) {
            max-width: 227px;
        }
    }
    &__in {
        @media all and (max-width: 960px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__info {
        position: relative;
        z-index: 1;
        padding: vw(49) vw(40);
        background: #FFFFFF;
        border-radius: 20px;
        margin-top: vw(20);
        margin-bottom: vw(35);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-height: vw(522);
        overflow: auto;
        @media all and (max-width: 860px) {
            max-height: 532px;
        }
        &--l {
            // width: calc(100% - vw(306));
            width: 68%;
            @media all and (max-width: 860px) {
                width: 100%;
            }
        }
        &--r {
            flex: 0 0 vw(241);
            margin-left: vw(65);
            img {
                position: relative;
                top: vw(-28);
            }
            @media all and (max-width: 860px) {
                display: none;
            }
        }
        @media all and (max-width: 960px) {
            padding: 20px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        @media all and (max-width: 860px) {
            margin-top: 20px;
            padding: 20px 10px;
            border-radius: 20px;
            max-height: 350px;
            overflow: unset;
        }
    }
    &__title {
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: vw(40);
        color: #FFFFFF;
        margin-bottom: vw(82);
        position: relative;
        z-index: 1;
        max-width: vw(720);
        width: 100%;
        @media all and (max-width: 860px) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 40px;
            max-width: 172px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
    &__fr {
        max-width: vw(1125);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100px;
        padding: vw(94) vw(10) vw(83) vw(10);
        transition: all .3s ease-out;
        @media all and (max-width: 960px) {
            padding-top: vw(90);
            padding-bottom: vw(60);
        }
        @media all and (max-width: 860px) {
            padding-bottom: 52px;
            padding-left: 0px;
            padding-right: 0px;
            border-radius: 30px;
            box-shadow: none;
            padding-top: 50px;
            max-width: 100%;
        }
    }
    &__scroll {
        position: relative;
        &--numb {
            position: absolute;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: vw(24);
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.7);
            top: vw(-44);
            margin-left: -35px;
            @media all and (max-width: 860px) {
                font-size: 20px;
                margin-left: -26px;
                top: -34px;
            }
        }
        &--bar {
            background: #FFFFFF;
            border-radius: 5px;
            height: vw(11);
            @media all and (max-width: 860px) {
                height: 11px;
            }
            span {
                border-radius: 5px;
                display: block;
                height: 100%;
                background: #FF5000;
                border-radius: 5px;
                position: relative;
                overflow: hidden;
            }
        }
    }
    &__ques {
        &--title {
            margin-bottom: vw(25);
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(30);
            color: #000000;
            @media all and (max-width: 860px) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
        &--radio {
            height: max-content !important;
            @media all and (max-width: 860px) {
                height: 200px !important;
                overflow: auto;
            }
        }
        transition: all 1s ease-out;
        visibility: hidden;
        position: absolute;
        bottom: 99999px;
        @media all and (min-width: 1024px) {
            transform: translate(0, 100%);
        }
        &.active {
            position: relative;
            @media all and (min-width: 1024px) {
                transform: translate(0px, 0px);
            }
            bottom: unset;
            visibility: visible;
        }
    }
    &__next {
        margin-top: vw(24);
        margin-right: auto;
        background: #FF5000;
        border: 1px solid #FF5000;
        border-radius: 10px;
        max-width: vw(240);
        width: 100%;
        padding: vw(18.5) vw(10);
        text-align: center;
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        transition: all .3s ease-out;
        font-size: vw(20);
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 1;
        &:hover {
            background: #fff;
            color: #FF5000;
        }
        @media all and (max-width: 860px) {
            max-width: 100%;
            padding: 12px 10px;
            font-size: 13px;
        }
    }
}

// radio
.form_radio {
	margin-bottom: vw(10);
    height: max-content !important;
}
.form_radio input[type=radio] {
	display: none;
}
.form_radio label {
	display: flex;
    align-items: center;
	cursor: pointer;
	position: relative;
	padding-left: vw(60);
	margin-right: 0;
    min-height: vw(30);
	user-select: none;
    margin-bottom: vw(10);
    font-family: 'gm', serif;
    font-style: normal;
    font-weight: normal;
    font-size: vw(18);
    line-height: 140%;
    text-transform: lowercase;
    color: #000000;
    @media all and (max-width: 860px) {
        padding-left: 20px;
        font-size: 12px;
        margin-bottom: 12px;
    }
}
.form_radio label:before {
	content: "";
	display: inline-block;
	width: vw(30);
	height: vw(30);
	position: absolute;
	left: vw(10);
    top: 50%;
    margin-top: vw(-15);
	bottom: 1px;
	border: vw(2) solid #FF5000;
    border-radius: 50%;
    @media all and (max-width: 860px) {
        top: 15px;
        left: 0;
        border-width: 1px;
        width: 16px;
        height: 16px;
    }
}
 
/* Checked */
.form_radio input[type=radio]:checked + label:before {
	background: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L5.5 10L13 1' stroke='%23FF5000' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A") 50% no-repeat;
    @media all and (max-width: 860px) {
        background-size: 8px;
    }
}
.form_radio input[type=radio]:checked + label {
    font-family: 'gmm', serif;
}
/* Hover */
.form_radio label:hover:before {
	filter: brightness(120%);
}



.abc2  {
    .main_form__ques.active {
        height: max-content !important;
        .atransomas {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
        .bl0 {
            animation-delay: 0s !important;
        }
        .bl1 {
            animation-delay: 0.3s !important;
        }
        .bl2 {
            animation-delay: 0.6s !important;
        }
        .bl3 {
            animation-delay: 0.9s !important;
        }
        .bl4 {
            animation-delay: 1.1s !important;
        }
        .bl5 {
            animation-delay: 1.5s !important;
        }
        .bl6 {
            animation-delay: 1.5s !important;
        }
        .bl7 {
            animation-delay: 1.8s !important;
        }
        .bl8 {
            animation-delay: 2.1s !important;
        }
        .bl9 {
            animation-delay: 2.5s !important;
        }
        @-webkit-keyframes dvizheniyaUp2 {
            from {
                opacity: 0;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
            }
            
            to {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
            }
            @keyframes dvizheniyaUp2 {
            from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            }
            
            to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
            }
            }
            
            .dvizheniyaUp2 {
            -webkit-animation-name: dvizheniyaUp;
            animation-name: dvizheniyaUp;
            }
    }
}