.main_rev {
    max-width: 1668px;
    width: 100%;    
    margin-left: auto;
    margin-right: auto;
    background: url(../img/bg2.png) 29px 50% no-repeat, #DCF3EF url(../img/bg1.png) 0 0 no-repeat;
    border-radius: 100px;
    position: relative;
    z-index: 11;
    padding-top: 60px;
    padding-bottom: 40px;
    @media all and (max-width: 1700px) {
        margin-left: 15px;
        margin-right: 15px;
        max-width: calc(100% - 30px);
    }
    @media all and (max-width: 1550px) {
        padding-bottom: 106px;
        border-radius: 30px;
    }
    @media all and (max-width: 640px) {
        padding-top: 36px;
        background: #DCF3EF;
    }
    &__in {

    }
    &__title {
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        color: #26364B;
        margin-bottom: 60px;
        @media all and (max-width: 640px) {
            font-size: 24px;
            margin-bottom: 41px;
        }
    }
    .slick-slide {
        margin: 0 40px;
        @media all and (max-width: 1390px) {
            margin: 0 20px;
        }
    }
    &__blocks {
        @media all and (max-width: 1550px) {
            position: unset !important;
        }
    }
    .slick-list {
        margin-left: -40px;
        margin-right: -40px;
        @media all and (max-width: 1390px) {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
    .slick-prev, .slick-next {
        &:before {
            display: none !important;
        }
        font-size: 0; 
        width: 72px;
        height: 72px;
        border-radius: 50%;
        z-index: 11;
        margin-top: -36px;
        border: 1px solid #66C9BA;
        background: #DCF3EF url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%2366C9BA'/%3E%3C/svg%3E%0A") 50% no-repeat;
        transition: all .3s ease-out;
        &:hover {
            background: #66C9BA url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1333 17.9595L2.50989 1.33611C1.95957 0.80462 1.08262 0.819879 0.551129 1.3702C0.0326223 1.90705 0.0326223 2.75811 0.551129 3.29488L16.1951 18.9389L0.551129 34.5829C0.0103008 35.1238 0.0103008 36.0007 0.551129 36.5416C1.09212 37.0825 1.96899 37.0825 2.50989 36.5416L19.1333 19.9183C19.6741 19.3773 19.6741 18.5004 19.1333 17.9595Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") 50% no-repeat;
        }
        @media all and (max-width: 1550px) {
            bottom: 0;
            top: unset;
        }
        &.slick-disabled {
            display: none !important;
        }
    }
    .slick-prev {
        left: -99px;
        transform: rotate(180deg);
        margin-top: -72px;
        @media all and (max-width: 1550px) {
            left: 20px;
            bottom: 24px;
            margin-top: 0;
        }
    }
    .slick-next {
        right: -99px;
        @media all and (max-width: 1550px) {
            right: 20px;
            bottom: 24px;
            margin-top: 0;
            transform: unset;
        }
    }
    &__block {
        padding-right: 5px;
        &--date {
            font-family: 'gml', serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            color: #000000;
            margin-bottom: 17px;
        }
        &--text {
            font-family: 'gml', serif;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            margin-bottom: 23px;
            max-height: 277px;
            overflow: auto;
            padding-right: 10px;
        }
        &--info {
            display: flex;
            align-items: center;
        }
        &--img {
            flex: 0 0 67px;
            margin-right: 18px;
            font-size: 0;
            img {
                overflow: hidden;
                border-radius: 50%;
            }
            @media all and (max-width: 640px) {
                margin-right: 12px;
            }
        }
        &--name {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #000000;
            margin-bottom: 6px;
        }
        &--link {
            text-decoration: none;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #000000;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}