.main_sl {
    margin-bottom: vw(20);
    @media all and (max-width: 1023px) {
        margin-bottom: 20px;
    }
    &__title {
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: vw(40);
        line-height: 120%;
        color: #26364B;
        margin-bottom: vw(40);
        @media all and (max-width: 1023px) {
            padding-left: 0px;
            padding-right: 0px;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 36px;
            margin-top: 30px;
        }
    }
    &__slides {
        border-radius: 30px;
        background: #FAFAFA;
        position: relative;
    }
    .slick-dots {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        margin-top: vw(-80);
        right: vw(40);
        width: vw(10);
        @media all and (max-width: 1023px) {
            margin: 0;
            top: unset;
            bottom: 20px;
            left: 0;
            right: 0;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        li {
            padding: 0;
            width: auto;
            margin: 0;
            height: auto;
            button {
                margin: 0;
                background: #BABABA;
                border-radius: 5px;
                width: vw(5);
                height: vw(5);
                padding: 0;
                transition: all .3s ease-out;
                margin-top: vw(15);
                margin-bottom: vw(15);
                &:before {
                    display: none !important;
                }
                @media all and (max-width: 1023px) {
                    margin: 0;
                    margin-left: 15px;
                    margin-right: 15px;
                    width: 5px;
                    height: 5px;
                }
            }
            &.slick-active {
                button {
                    width: vw(10);
                    height: vw(20);
                    background: #FF5000;
                    border-radius: 45px;   
                    @media all and (max-width: 1023px) {
                        width: 20px;
                        height: 10px;
                    }             
                }
            }
        }
    }
    &__slide {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 1023px) {
            flex-direction: column;
            justify-content: center;
        }
        &--l {
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
            font-size: 0;
            @media all and (max-width: 1023px) {
                text-align: center;
                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        &--r {
            max-width: 50%;
            width: 100%;
            flex: none;
            padding-left: vw(60);
            @media all and (max-width: 1023px) {
                max-width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: 30px;
                padding-bottom: 50px;
            }
        }
        &--title {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(40);
            line-height: 120%;
            max-width: vw(440);
            width: 100%;
            color: #26364B;
            margin-bottom: vw(30);
            @media all and (max-width: 1023px) {
                max-width: 100%;
                font-size: 24px;
                margin-bottom: 15px;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: vw(20);
            line-height: 140%;
            color: #3D3D3D;
            max-width: vw(530);
            width: 100%;
            @media all and (max-width: 1023px) {
                max-width: 100%;
                font-size: 18px;
            }
        }
    }
}